import PropTypes from 'prop-types';
import { Outlet } from 'react-router';

const Root = ({ children }) => (
  <>
    {children || <Outlet />}
  </>
);

Root.propTypes = {
  children: PropTypes.node
};

export default Root;
