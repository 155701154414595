import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Link } from '@mui/material';
import LoginJWT from '../../components/authentication/LoginJWT';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import { STORES } from '../../constants';

const Login = () => {
  const { storeId } = useParams();
  const { platform } = useAuth();

  return (
    <>
      {STORES[storeId]
      && (
      <Helmet>
        <title>Login</title>
      </Helmet>
      )}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to=".">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                px: 4,
                pt: 2
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 0
                }}
              >
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="./password-recovery"
                variant="body2"
              >
                Forgot password
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
