import { Card, CardActions, CardHeader, Button, Typography, CardContent, Divider, useTheme, alpha, useMediaQuery, Skeleton, Alert, Slide } from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { Link as RouterLink, useParams } from 'react-router-dom';
import OrderApprovalOverviewBox from './OrderApprovalOverviewBox';
import { useEffect, useState } from 'react';
import { portalApi } from '../../../utils/apiConfig';
import { QUEUES } from '../../../constants';
import Error from '@mui/icons-material/Error';

const OrderApprovalOverview = (props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { storeId } = useParams();

  const [queueCount, setQueueCount] = useState((() => {
    const result = {};

    Object.keys(QUEUES).forEach((q) => {
      result[QUEUES[q]] = null;
    });

    return result;
  })());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const requests = [];
    Object.keys(QUEUES).forEach((queue) => {
      requests.push(portalApi.get(`api/order/store/${storeId}/queue/${QUEUES[queue]}/count`));
    });

    Promise.all(requests)
      .then((responses) => {
        const result = {};
        responses.forEach((response) => {
          const queueData = response.data;
          result[queueData.queue] = queueData.count;
        });
        setQueueCount(result);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      });
  }, []);

  return (
    <Card {...props}>
      {error ? (
        <Slide
          direction="down"
          in={error}
        >
          <Alert
            severity="error"
            sx={{ ml: 'auto', m: 1 }}
          >
            Order Approval: Error loading data
          </Alert>
        </Slide>

      ) : <CardHeader title="Order Approval" />}

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: smDown ? 'column' : 'row',
          gap: '20px',
          justifyContent: 'space-around'
        }}
      >
        <RouterLink
          to="./order-approval?filter=needs-approval"
          style={{ textDecoration: 'none', color: 'white', flex: 1 }}
        >
          <OrderApprovalOverviewBox
            sx={{
              borderColor: 'warning.main',
              backgroundColor: queueCount[QUEUES['needs-approval']] > 0 && !loading
                ? alpha(theme.palette.warning.main, 0.3)
                : '',
              ':hover': {
                backgroundColor: alpha(theme.palette.warning.main, 0.5)
              }
            }}
          >
            <Typography
              variant="h1"
            >
              {error
                ? <Error fontSize="large" />
                : (
                  <>
                    {loading
                      ? <Skeleton width={40} />
                      : queueCount[QUEUES['needs-approval']]}
                  </>
                )}

            </Typography>
            <Typography
              variant="div"
              sx={{ textAlign: 'center' }}
            >
              Need Approval
            </Typography>
          </OrderApprovalOverviewBox>
        </RouterLink>

        <RouterLink
          to="./order-approval?filter=rejected"
          style={{ textDecoration: 'none', color: 'white', flex: 1 }}
        >
          <OrderApprovalOverviewBox
            sx={{
              borderColor: 'error.main',
              ':hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.5)
              }
            }}
          >
            <Typography
              variant="h1"
            >
              {error
                ? <Error fontSize="large" />
                : (
                  <>
                    {loading
                      ? <Skeleton width={40} />
                      : queueCount[QUEUES.rejected]}
                  </>
                )}
            </Typography>
            <Typography
              variant="div"
              sx={{ textAlign: 'center' }}
            >
              Rejected
            </Typography>
          </OrderApprovalOverviewBox>
        </RouterLink>

        <RouterLink
          to="./order-approval?filter=approved"
          style={{ textDecoration: 'none', color: 'white', flex: 1 }}
        >
          <OrderApprovalOverviewBox
            sx={{
              borderColor: 'success.main',
              ':hover': {
                backgroundColor: alpha(theme.palette.success.main, 0.5)
              }
            }}
          >
            <Typography
              variant="h1"
            >
              {error
                ? <Error fontSize="large" />
                : (
                  <>
                    {loading
                      ? <Skeleton width={40} />
                      : queueCount[QUEUES.approved]}
                  </>
                )}
            </Typography>
            <Typography
              variant="div"
              sx={{ textAlign: 'center' }}
            >
              Approved
            </Typography>
          </OrderApprovalOverviewBox>
        </RouterLink>
      </CardContent>
      <Divider />
      <CardActions>
        <RouterLink to="./order-approval">
          <Button
            color="primary"
            endIcon={<ArrowRightIcon fontSize="small" />}
            variant="text"
          >
            View orders
          </Button>
        </RouterLink>
      </CardActions>
    </Card>
  );
};

export default OrderApprovalOverview;
