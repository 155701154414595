import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const OrderApprovalOverviewBox = (props) => {
  const { children, ...rest } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: 1,
        borderBottomWidth: 3,
        textDecoration: 'none',
        color: 'text.primary',
        ...rest.sx
      }}
    >
      {children}
    </Box>
  );
};

OrderApprovalOverviewBox.propTypes = {
  children: PropTypes.node
};

export default OrderApprovalOverviewBox;
