import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AppBar, Divider, Toolbar, Box, Button } from '@mui/material';
import Logo from './Logo';
import { useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';

const MainNavbar = () => {
  const { storeId } = useSelector((state) => state.stores);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      if (storeId) {
        navigate(`/${storeId}`);
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink
          to="./"
          style={{ lineHeight: 0 }}
        >
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>
        { user && user.storeClaims.includes(storeId)
                && (
                <Box sx={{ marginLeft: 'auto' }}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={handleLogout}
                    variant="outlined"
                  >
                    Logout
                  </Button>
                </Box>
                )}
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
