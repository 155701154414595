import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STORES } from '../constants';
import { Outlet } from 'react-router';
import { useEffect } from 'react';
import storesSlice from '../slices/stores';

const StoreProvider = ({ children }) => {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!STORES[storeId]) {
      if (location.pathname !== '/404') {
        navigate('/404');
      }
    } else {
      dispatch(storesSlice.actions.setCurrentStore({ storeId }));
    }
  }, []);

  return (
    <>
      {children || <Outlet />}
    </>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node
};

export default StoreProvider;
