import { useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { STORES } from '../constants';

const Logo = () => {
  const { storeId } = useParams();
  const theme = useTheme();

  let imagePath = '/static/';
  let altText = '';
  const darkMode = theme.palette.mode === 'dark' ? '-dark' : '';

  if (STORES[storeId]) {
    imagePath += `logos/${storeId}${darkMode}.png`;
    altText = `${storeId} logo`;
  } else {
    imagePath += `logos/wmg${darkMode}.png`;
    altText = 'Wild Marketing Group logo';
  }

  return (
    <img
      src={imagePath}
      alt={altText}
      height="38"
    />
  );
};

export default Logo;
