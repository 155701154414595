import { portalApi } from '../utils/apiConfig';
import jwtDecode from 'jwt-decode';

class AuthApi {
  async login({ email, password, store }) {
    return new Promise((resolve, reject) => {
      portalApi.post(
        '/api/token',
        { email, password, store }
      )
        .then((response) => {
          try {
            jwtDecode(response.data.token);
            resolve(response.data.token);
          } catch (error) {
            console.log('[Auth Api JWT]:', error);
            reject(new Error('Internal server error'));
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            reject(new Error('Please check your email and password'));
          } else {
            console.error('[Auth Api]: ', error);
            reject(new Error('Internal server error'));
          }
        });
    });
  }

  async forgotPassword(email, storeId) {
    return new Promise((resolve, reject) => {
      portalApi.post(
        '/api/user/forgotpassword',
        { email, storeId }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('[Auth Api]', error);
          reject(new Error('Internal server error'));
        });
    });
  }

  async resetPassword(email, password, resetToken) {
    return new Promise((resolve, reject) => {
      portalApi.post(
        '/api/User/resetpassword',
        { email, newPassword: password, resetToken }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log('[Auth Api]', error);
          reject(new Error('Internal server error'));
        });
    });
  }

  me() {
    return new Promise((resolve, reject) => {
      portalApi.get('/api/user/me')
        .then((response) => {
          const user = response.data;
          resolve({
            id: user.id,
            email: user.email,
            name: user.userName,
            storeClaims: user.storeClaims
          });
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            reject(new Error('Invalid authorization token'));
          } else {
            console.error('[Auth Api]: ', error);
            reject(new Error('Internal server error'));
          }
        });
    });
  }
}

export const authApi = new AuthApi();
