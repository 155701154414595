import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import StoreProvider from './components/StoreProvider';
import Root from './components/Root';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const LandingDefault = Loadable(lazy(() => import('./pages/LandingDefault')));

// Authentication pages
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
// const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
// const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Feature pages
const OrderApproval = Loadable(lazy(() => import('./pages/features/OrderApproval')));
const OrderDetails = Loadable(lazy(() => import('./pages/features/OrderDetails')));

const routes = [
  {
    path: '*',
    element: <Root />,
    children: [
      {
        path: '/',
        element: (
          <MainLayout>
            <LandingDefault />
          </MainLayout>
        )
      },
      {
        path: ':storeId',
        element: (
          <StoreProvider>
            <MainLayout />
          </StoreProvider>
        ),
        children: [
          {
            path: '/',
            element: (
              <AuthGuard>
                <Dashboard />
              </AuthGuard>
            )
          },
          {
            path: 'order-approval',
            element: <AuthGuard />,
            children: [
              {
                path: '/',
                element: <OrderApproval />
              },
              {
                path: ':orderId',
                element: <OrderDetails />
              },
            ]
          },
          {
            path: 'password-recovery',
            element: <PasswordRecovery />
          },
          {
            path: 'password-reset',
            element: <PasswordReset />
          },
          {
            path: '401',
            element: <AuthorizationRequired />
          },
          {
            path: '404',
            element: <NotFound />
          },
          {
            path: '500',
            element: <ServerError />
          },
          {
            path: '*',
            element: <NotFound />
          }
        ]
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
