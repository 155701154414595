import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storeId: ''
};

const slice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setCurrentStore(state, action) {
      const { payload } = action;
      const { storeId } = payload;
      return {
        ...state,
        storeId
      };
    }
  }
});

export const { reducer } = slice;

export default slice;
