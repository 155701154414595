import OrderApprovalOverview from './components/features/OrderApproval/OrderApprovalOverview';
import OrderApproval from './pages/features/OrderApproval';

export const THEMES = {
  SNAPON: {
    LIGHT: 'SNAPON.LIGHT',
    DARK: 'SNAPON.DARK'
  },
  WMG: {
    LIGHT: 'WMG.LIGHT',
    DARK: 'WMG.DARK'
  },
  'OPERATION-SMILE': {
    LIGHT: 'OPERATIONSMILE.LIGHT',
    DARK: 'OPERATIONSMILE.DARK'
  }
};

export const STORES = {
  snapon: {
    id: 'SNAPON',
    humanReadableName: 'Snap-on'
  },
  'operation-smile': {
    id: 'OPERATION-SMILE',
    humanReadableName: 'Operation Smile'
  }
};

export const FEATURES = {
  'order-approval': {
    page: <OrderApproval />,
    overview: <OrderApprovalOverview />
  }
};

export const QUEUES = {
  'needs-approval': 'awaiting_approval',
  approved: 'successfully_retrieved_orders',
  rejected: 'rejected_orders',
  process: 'new_and_updated'
};
