import { Fab } from '@mui/material';
import useSettings from '../hooks/useSettings';

const ThemeToggle = () => {
  const settings = useSettings();
  const { theme } = settings.settings;

  const handleClick = () => settings.saveSettings({ theme: theme === 'DARK' ? 'LIGHT' : 'DARK' });

  return (
    <Fab
      color="primary"
      onClick={handleClick}
      size="medium"
      sx={{
        bottom: 0,
        margin: (t) => t.spacing(4),
        position: 'fixed',
        right: 0,
        zIndex: (t) => t.zIndex.speedDial
      }}
    >
      <span style={{ fontSize: 20 }}>
        { theme === 'DARK'
          ? '🌞'
          : '🌛'}
      </span>
    </Fab>
  );
};

export default ThemeToggle;
