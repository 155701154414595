import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Link,
  Breadcrumbs,
  Skeleton,
  Button
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Toaster from 'react-hot-toast';
import ArrowRight from '../../icons/ArrowRight';
import StatusLabel from '../../components/features/OrderApproval/StatusLabel';
import Scrollbar from '../../components/Scrollbar';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { portalApi } from '../../utils/apiConfig';
import { QUEUES } from '../../constants';
import ErrorIcon from '@mui/icons-material/Error';
import { getStatusByQueue, orderActions } from '../../utils/queues';
import NotesDialog from '../../components/features/OrderApproval/NotesDialog';

const mockOrders = [
  {
    id: 1
  }
];

const OrderApproval = () => {
  const { storeId } = useParams();

  const [searchParams] = useSearchParams();
  const initialFilter = searchParams.get('filter') || 'needs-approval';
  const [statusFilter, setStatusFilter] = useState(initialFilter);
  const [orders, setOrders] = useState(mockOrders);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [notesDialog, setNotesDialog] = useState(false);
  const [ordersBeingActioned, setOrdersBeingActioned] = useState([]);

  const selectedSomeOrders = selectedOrders.length > 0 && selectedOrders.length < orders.length;
  const selectedAllOrders = selectedOrders.length === orders.length;

  const handleSelectAllOrders = (event) => {
    setSelectedOrders(event.target.checked
      ? orders.map((order) => order.id)
      : []);
  };

  const handleSelectOneOrder = (event, orderId) => {
    if (!selectedOrders.includes(orderId)) {
      setSelectedOrders((prevSelected) => [...prevSelected, orderId]);
    } else {
      setSelectedOrders((prevSelected) => prevSelected.filter((id) => id !== orderId));
    }
  };

  const handleStatusFilterChange = (event) => {
    setLoading(true);
    setSelectedOrders([]);
    setOrders(mockOrders);
    setStatusFilter(event.target.value);
  };

  const getOrderList = (filter) => {
    setLoading(true);
    setError(false);
    setSelectedOrders([]);
    portalApi.get(`api/order/store/${storeId}/queue/${QUEUES[filter]}`)
      .then((response) => {
        setOrders(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          setOrders([]);
        } else {
          setError(true);
        }
        setLoading(false);
        console.log(e);
      });
  };

  const moveOrders = (filter, orderIds) => {
    if (typeof orderIds === 'number') {
      orderIds = [orderIds];
    }

    orderActions.moveOrders(storeId, orderIds, filter)
      .then(() => {
        getOrderList(statusFilter);
        const message = orderIds.length === 1
          ? `Order ${orderIds} ${filter}!`
          : `Orders ${orderIds.join(', ')} ${filter}!`;
        Toaster.success(message);
      })
      .catch(() => {
        console.log(orderIds);
        const message = orderIds.length === 1
          ? `Something went wrong. Order ${orderIds} was not ${filter}.`
          : `Something went wrong. Orders ${orderIds.join(', ')} not ${filter}`;
        Toaster.error(message);
      });
  };

  const openNotesDialog = (ordersToAction) => {
    setOrdersBeingActioned(ordersToAction);
    setNotesDialog(true);
  };

  const handleCloseRejectedDialog = () => {
    setNotesDialog(false);
  };

  useEffect(() => {
    getOrderList(statusFilter);
  }, [statusFilter]);

  return (
    <>
      <Helmet>
        <title>Order Approval</title>
      </Helmet>

      <NotesDialog
        open={notesDialog}
        orders={ordersBeingActioned}
        onClose={handleCloseRejectedDialog}
        onSave={(orderIds) => moveOrders('rejected', orderIds)}
        required
      />

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Order Approval
          </Typography>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator="/"
            sx={{ mt: 1 }}
          >
            <Link
              component={RouterLink}
              underline="hover"
              to="../"
            >
              Dashboard
            </Link>
            <Typography
              color="text.secondary"
            >
              Order Approval
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <FormControl sx={{ minWidth: 160 }}>
              <InputLabel id="status-filter-label">Status Filter</InputLabel>
              <Select
                labelId="status-filter-label"
                id="status-filter"
                value={statusFilter}
                label="Status Filter"
                onChange={handleStatusFilterChange}
              >
                <MenuItem value="needs-approval">Needs Approval</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
              </Select>
            </FormControl>
            {statusFilter !== 'approved'
            && (
            <Button
              variant="contained"
              color="success"
              disabled={selectedOrders.length === 0}
              onClick={() => moveOrders('approved', selectedOrders)}
            >
              Approve Orders
            </Button>
            )}
            {statusFilter !== 'approved' && statusFilter !== 'rejected'
            && (
            <Button
              variant="contained"
              color="error"
              disabled={selectedOrders.length === 0}
              onClick={() => openNotesDialog(selectedOrders.map((orderId) => orders.find((o) => o.id === orderId)))}
            >
              Reject Orders
            </Button>
            )}
          </Box>
          <Card sx={{ mt: 2 }}>
            {error
            && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', my: 4, gap: 1 }}>
              <ErrorIcon
                color="error"
                sx={{ fontSize: 50 }}
              />
              <Typography variant="h5">
                Something went wrong
              </Typography>
              <Typography>
                If this issue persists, please contact support.
              </Typography>
              <Button
                variant="contained"
                onClick={() => getOrderList(statusFilter)}
              >
                Refresh
              </Button>
            </Box>
            )}
            {!error
            && (
            <Scrollbar>
              <Box sx={{ minWidth: 1150 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllOrders}
                          color="primary"
                          indeterminate={selectedSomeOrders}
                          onChange={handleSelectAllOrders}
                        />
                      </TableCell>
                      <TableCell>
                        Order #
                      </TableCell>
                      <TableCell>
                        Date
                      </TableCell>
                      <TableCell>
                        Customer
                      </TableCell>
                      <TableCell>
                        Total
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      {(statusFilter === 'rejected' || statusFilter === 'approved')
                      && (
                      <>
                        <TableCell>
                          {statusFilter === 'rejected' ? 'Rejected' : 'Approved'}
                          {' '}
                          By
                        </TableCell>
                        <TableCell>
                          {statusFilter === 'rejected' ? 'Rejection' : 'Approval'}
                          {' '}
                          Date
                        </TableCell>
                      </>
                      )}

                      <TableCell
                        align="right"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.length === 0 && !loading && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Typography
                          variant="h5"
                          align="center"
                        >
                          No orders were found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                    )}
                    {orders.map((order) => {
                      const isOrderSelected = selectedOrders.includes(order.id);
                      return (
                        <TableRow key={order.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isOrderSelected}
                              color="primary"
                              onChange={(event) => handleSelectOneOrder(event, order.id)}
                              value={isOrderSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Link
                              color="text.primary"
                              underline="hover"
                              component={RouterLink}
                              to={`./${order.id}`}
                            >
                              {loading && <Skeleton />}
                              {!loading && order.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {loading && <Skeleton />}
                            {!loading && new Date(order.orderDate * 1000).toLocaleString('en-US',
                              {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })}
                          </TableCell>
                          <TableCell>
                            <Typography
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {loading && <Skeleton />}
                              {!loading && order.customerName}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              {loading && <Skeleton />}
                              {!loading && order.customerEmail}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {loading && <Skeleton />}
                            {!loading && order.formattedTotal}
                          </TableCell>
                          <TableCell>
                            {loading && <Skeleton />}
                            {!loading && <StatusLabel status={statusFilter} />}
                          </TableCell>
                          {(statusFilter === 'rejected' || statusFilter === 'approved')
                          && (
                          <>
                            <TableCell>
                              {order.approverLogin}
                            </TableCell>
                            <TableCell>
                              {order.approverDate}
                            </TableCell>
                          </>
                          )}
                          <TableCell
                            align="right"
                          >
                            {loading && <Skeleton />}
                            {!loading
                          && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: 1,
                              align: 'right',
                            }}
                          >
                            {getStatusByQueue(order.queue) !== 'approved' && (
                            <IconButton
                              sx={{ backgroundColor: 'success.main' }}
                              title="Approve"
                              onClick={() => moveOrders('approved', order.id)}
                            >
                              <ThumbUpIcon fontSize="small" />
                            </IconButton>
                            )}
                            {getStatusByQueue(order.queue) !== 'rejected'
                          && getStatusByQueue(order.queue) !== 'approved' && (
                          <IconButton
                            sx={{ backgroundColor: 'error.main' }}
                            title="Reject"
                            onClick={() => openNotesDialog([order])}
                          >
                            <ThumbDownIcon fontSize="small" />
                          </IconButton>
                            )}
                            <RouterLink to={`./${order.id}`}>
                              <IconButton
                                title="Order Details"
                              >
                                <ArrowRight fontSize="small" />
                              </IconButton>
                            </RouterLink>
                          </Box>
                          )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TablePagination
                        count={orders.length}
                        onPageChange={() => {}}
                        page={0}
                        rowsPerPage={10}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
            )}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default OrderApproval;
