import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Grid, DialogActions, Button, TextField, Table, TableRow, TableCell, TableHead, TableBody, Slide, Alert } from '@mui/material';
import StatusLabel from './StatusLabel';
import { format } from 'date-fns';
import { getStatusByQueue } from '../../../utils/queues';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { portalApi } from '../../../utils/apiConfig';
import { useParams } from 'react-router';
import LoadingButton from '@mui/lab/LoadingButton';

const NotesDialog = ({ open, orders, onClose, onSave, required }) => {
  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const currentOrder = orders[currentOrderIndex];

  const [notesSaveLoading, setNotesSaveLoading] = useState(false);
  const [notesSaveError, setNotesSaveError] = useState(false);

  const { storeId } = useParams();

  useEffect(() => {
    if (open) {
      setCurrentOrderIndex(0);
    }
  }, [open]);

  const [orderNotes, setOrderNotes] = useState({});
  const currentOrderNotes = orderNotes[currentOrder?.id];

  useEffect(() => {
    const tempOrderNotes = {};
    orders.forEach((order) => {
      tempOrderNotes[order.id] = order.approverNotes;
    });
    setOrderNotes(tempOrderNotes);
  }, [orders]);

  const handleNotesChange = (event) => {
    setOrderNotes({
      ...orderNotes,
      [currentOrder.id]: event.target.value
    });
  };

  const saveNotes = () => new Promise((resolve, reject) => {
    orders.forEach((order) => {
      setNotesSaveLoading(true);
      const notes = orderNotes[order.id];
      if (order && notes && notes !== order.approverNotes) {
        portalApi.put(`/api/order/store/${storeId}/order/${order.id}/notes`, orderNotes[order.id],
          { headers: { 'Content-Type': 'application/json' } })
          .then(() => {
            setNotesSaveError(false);
            resolve();
          })
          .catch((error) => {
            setNotesSaveError(true);
            reject(error);
          })
          .finally(() => setNotesSaveLoading(false));
      } else {
        resolve();
      }
    });
  });

  const previousOrder = () => {
    setNotesSaveError(false);
    setCurrentOrderIndex(currentOrderIndex - 1);
  };

  const nextOrder = () => {
    setCurrentOrderIndex(currentOrderIndex + 1);
  };

  const closeDialog = () => {
    setNotesSaveError(false);
    onClose();
  };

  const submit = () => {
    saveNotes()
      .then(() => {
        onSave(orders.map((order) => order.id));
        onClose();
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Notes Required</DialogTitle>
      <DialogContent>
        {currentOrder
          && (
          <>
            <Grid
              container
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid item>
                Some orders need your comments before being rejected.
                Please review the orders below and enter the reason for rejecting each one.
              </Grid>
              <Grid
                item
                mt={2}
                xs={12}
                sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
              >
                <Typography variant="h6">
                  Order #
                  {currentOrder.id}
                </Typography>
                <StatusLabel status={currentOrder.status ?? getStatusByQueue(currentOrder.queue)} />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Typography fontWeight="bold">Date</Typography>
                {currentOrder.orderDate && format(new Date(currentOrder?.orderDate * 1000), 'dd/MM/yyyy HH:mm')}
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Typography fontWeight="bold">Total</Typography>
                {currentOrder.formattedTotal}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography fontWeight="bold">Customer</Typography>
                {currentOrder.customerName}
                <br />
                {currentOrder.customerEmail}
              </Grid>

            </Grid>
            <TextField
              label="Rejection Notes"
              multiline
              minRows={2}
              sx={{
                width: '100%',
              }}
              required={required}
              helperText={required && currentOrderNotes?.length === 0 ? 'This field is required.' : ''}
              error={required && currentOrderNotes?.length === 0}
              value={currentOrderNotes ?? ''}
              onChange={handleNotesChange}
            />
          </>
          )}
        {currentOrderIndex >= orders.length
            && (
            <>
              {notesSaveError && (
              <Slide
                direction="down"
                in={notesSaveError}
              >
                <Alert
                  severity="error"
                  sx={{ ml: 'auto', mb: 1 }}
                >
                  There was a problem saving notes. Please try again. If this problem persists, please contact support.
                </Alert>
              </Slide>
              )}
              <Typography variant="h6">Summary</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width="15%"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Order #
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Customer
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Notes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        {order.id}
                      </TableCell>
                      <TableCell>
                        {order.customerName}
                      </TableCell>
                      <TableCell>
                        {orderNotes[order.id]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
            )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={closeDialog}
          color="error"
          sx={{ mr: 'auto' }}
        >
          Cancel
        </Button>
        {currentOrderIndex !== 0
            && (
            <Button
              onClick={previousOrder}
              color="warning"
            >
              <ArrowBackIcon />
              Previous
            </Button>
            )}
        {orders.length > currentOrderIndex
            && (
            <Button
              onClick={nextOrder}
              disabled={required ? (currentOrderNotes?.length === 0 || !currentOrderNotes) : false}
              color="success"
            >
              Next
              {' '}
              <ArrowForwardIcon />
            </Button>
            )}
        {currentOrderIndex === orders.length
            && (
            <LoadingButton
              onClick={submit}
              color="success"
              loading={notesSaveLoading}
            >
              Reject Orders
              <CheckIcon />
            </LoadingButton>
            )}
      </DialogActions>
    </Dialog>
  );
};

NotesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  orders: PropTypes.array,
  required: PropTypes.bool
};

export default NotesDialog;
