import { QUEUES } from '../constants';
import { portalApi } from './apiConfig';

export const getStatusByQueue = (queue) => Object.keys(QUEUES).find((q) => QUEUES[q] === queue);

export const orderActions = {
  moveOrders: (storeId, orderIds, filter) => new Promise((resolve, reject) => {
    const filters = filter === 'approved'
      ? ['approved', 'process']
      : ['rejected'];
    portalApi.patch(`api/order/store/${storeId}/queues`, {
      orderIds,
      orderQueues: filters.map((q) => QUEUES[q])
    })
      .then(() => resolve())
      .catch(reject);
  }) };
