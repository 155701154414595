import PropTypes from 'prop-types';
import Label from '../../Label';

const StatusLabel = (props) => {
  const { status } = props;

  const map = {
    'needs-approval': {
      color: 'warning',
      text: 'Needs Approval'
    },
    rejected: {
      color: 'error',
      text: 'Rejected'
    },
    approved: {
      color: 'success',
      text: 'Approved'
    }
  };

  const { text, color } = map[status];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string
};

export default StatusLabel;
