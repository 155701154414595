import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import MainNavbar from './MainNavbar';
import ThemeToggle from './ThemeToggle';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { STORES } from '../constants';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64
}));

const MainLayout = ({ children }) => {
  const { storeId } = useSelector((state) => state.stores);
  const storeName = STORES[storeId]?.humanReadableName;
  const titleTemplate = storeId
    ? `%s - ${storeName} - Admin Portal`
    : '%s - Wild Marketing Group - Admin Portal';
  const faviconPath = storeId
    ? `/static/icons/${storeId}.ico`
    : '/static/icons/wmg.ico';

  return (
    <>
      <Helmet
        defaultTitle="Wild Marketing Group - Admin Portal"
        titleTemplate={titleTemplate}
      >
        <link
          rel="icon"
          href={faviconPath}
          type="image/x-icon"
        />
      </Helmet>
      <MainLayoutRoot>
        <MainNavbar />
        {children || <Outlet />}
        <ThemeToggle />
      </MainLayoutRoot>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
